<template>
    <header
        class="home-hero"
        :class="classes"
    >
        <div
            v-if="isBglf"
            class="home-hero__background-sticky"
        >
            <div class="home-hero__background-sticky__stick" />
        </div>

        <template v-if="!isParticipaties && hasCasesOrProjects">
            <div
                class="home-hero__background-one"
                data-aos="custom-background-one"
                data-aos-anchor="#anchor-background-0"
            />
            <div
                v-if="casesOrProjects.length > 1"
                class="home-hero__background-two"
                data-aos="custom-background-two"
                data-aos-anchor="#anchor-background-1"
            />
            <div
                v-if="casesOrProjects.length > 2"
                class="home-hero__background-three"
                data-aos="custom-background-three"
                data-aos-anchor="#anchor-background-2"
            />
            <div
                class="home-hero__background-reset"
                data-aos="custom-background-reset"
                data-aos-anchor="#anchor-background-reset"
            />
        </template>

        <div class="home-hero__title">
            <h1
                class="home-hero__title__header"
                :style="{ opacity: titleOpacity }"
            >
                <template v-if="isBglf">Het VriendenLoterij Fonds ondersteunt voorlopers die zich inzetten voor een wereld waarin iedereen meedoet en waar ruimte is voor radicale verbeelding</template>
                <template v-else-if="isParticipaties">{{ t('home.participaties_hero_text') }}</template>
                <template v-else>{{ t('home.hero_text') }}</template>
            </h1>
        </div>

        <div
            v-if="hasCasesOrProjects"
            class="home-hero__previews"
        >
            <div
                v-for="(item, index) in casesOrProjects"
                :key="index"
                class="home-hero__previews__item"
            >
                <ProjectPreview
                    v-if="item"
                    class="home-hero__previews__item__card"
                    :case-or-project="item"
                    :index="index"
                    preview-class="home-hero__previews__item__card__block"
                />
                <div
                    :id="`anchor-background-${index}`"
                    class="home-hero__previews__item__anchor"
                />
            </div>
            <div class="home-hero__previews__item home-hero__previews__more">
                <div class="home-hero__previews__item__card home-hero__previews__item__more">
                    <MoreProjects
                        class="home-hero__previews__item__card__block"
                    />
                </div>
                <div
                    id="anchor-background-reset"
                    class="home-hero__previews__item__anchor"
                />
            </div>
        </div>
    </header>
</template>

<script setup>
import { useWindowScroll, useWindowSize } from '@vueuse/core';

const props = defineProps({
    casesOrProjects: {
        type: Array,
        required: false,
        default() { return []; }
    }
});

const { casesOrProjects } = toRefs(props);

const { t } = useI18n();
const { isParticipaties, isBglf } = useMultisite();

const hasCasesOrProjects = computed(() => casesOrProjects.value?.length > 0);
const classes = computed(() => {
    if (hasCasesOrProjects.value) {
        if (casesOrProjects.value?.length === 1) {
            return 'home-hero--preview home-hero--preview-one';
        } else if (casesOrProjects.value?.length === 2) {
            return 'home-hero--preview home-hero--preview-two';
        } else if (casesOrProjects.value?.length >= 3) {
            return 'home-hero--preview home-hero--preview-three';
        }
    }

    return '';
});

const offsetAnimation = 150;
const { y } = useWindowScroll();
const { height } = useWindowSize();

const titleOpacity = computed(() => {
    if (y.value && height.value) {
        return 1 - y.value / ((height.value / 2) - offsetAnimation);
    }

    return 1;
});
</script>

<style lang="less" src="./HomeHero.less" />
