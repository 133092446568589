<template>
    <div class="project-preview">
        <NuxtLink
            :to="mainLink"
            class="project-preview__block"
            :class="previewClass"
            :style="background"
            data-aos="custom-zoom-to"
            :data-aos-anchor="`#anchor-background-${index}`"
        >
            <div class="project-preview__shade" />
            <div class="project-preview__content">
                <h3 class="project-preview__title">{{ caseOrProject?.title }}</h3>

                <!-- eslint-disable vue/no-v-html -->
                <div
                    v-if="caseOrProject?.featuredHTML"
                    class="project-preview__featuredText"
                    v-html="caseOrProject.featuredHTML"
                />
                <!-- eslint-enable vue/no-v-html -->
            </div>
            <div
                class="project-preview__footer"
                :data-color="iconColor"
            >
                <div
                    v-if="isDoen && iconColor"
                    class="project-preview__footer__icon"
                >
                    <BoomerIcon v-if="iconColor === 'groen'">
                        <IconThemeGreen />
                    </BoomerIcon>
                    <BoomerIcon v-else-if="iconColor === 'sociaal'">
                        <IconThemeSocial />
                    </BoomerIcon>
                    <BoomerIcon v-else-if="iconColor === 'creatief'">
                        <IconThemeCreative />
                    </BoomerIcon>
                </div>

                <div class="project-preview__footer__title">
                    <h3>{{ caseOrProject?.title }}</h3>
                </div>

                <div
                    v-if="isDoen && programTitle"
                    class="project-preview__footer__subtitle"
                >
                    <h4>{{ programTitle }}</h4>
                </div>
            </div>
        </NuxtLink>
    </div>
</template>

<script setup>
import IconThemeGreen from '~/components/_icons/theme-green.vue';
import IconThemeSocial from '~/components/_icons/theme-social.vue';
import IconThemeCreative from '~/components/_icons/theme-creative.vue';

const props = defineProps({
    caseOrProject: {
        type: Object,
        required: true,
        default() { return {}; }
    },
    index: {
        type: Number,
        required: true,
        default: 0
    },
    previewClass: {
        type: String,
        required: false,
        default: ''
    }
});

const { caseOrProject } = toRefs(props);

const localePath = useLocalePath();

const TYPE_PROJECT = 'projects_projects_Entry',
    TYPE_CASE = 'case_case_Entry',
    TYPE_PORTFOLIO = 'portfolio_portfolio_Entry';

const { isDoen } = useMultisite();

const type = computed(() => caseOrProject.value?.__typename);
const programCategory = computed(() => caseOrProject.value?.programCategory?.[0]);

const mainLink = computed(() => {
    switch (type.value) {
    case TYPE_CASE:
        return localePath({ name: 'wat-we-doen-program-slug', params: { program: programCategory.value.slug, slug: caseOrProject.value.slug } });
    case TYPE_PORTFOLIO:
        return localePath({ name: 'portfolio-slug', params: { slug: caseOrProject.value.slug } });
    case TYPE_PROJECT:
    default:
        return localePath({ name: 'projecten-slug', params: { slug: caseOrProject.value.slug } });
    }
});

const featuredImage = computed(() => caseOrProject.value?.featuredImage?.[0]?.url);
const programTitle = computed(() => programCategory.value?.title ?? '');
const themeCategory = computed(() => programCategory.value?.themeCategory?.[0] ?? '');
const iconColor = computed(() => themeCategory.value?.slug ?? '');
const background = computed(() => {
    if (featuredImage?.value) {
        return `background-image: url(${featuredImage.value});`;
    }

    return '';
});
</script>

<style lang="less" src="./ProjectPreview.less" />
