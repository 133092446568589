<template>
    <div
        class="more-projects"
        data-aos="custom-zoom-to"
        data-aos-anchor="#anchor-background-reset"
    >
        <h3 class="more-projects__title">
            <i18n-t
                v-if="isParticipaties"
                keypath="project_preview.more_portfolio"
            >
                <template #portfolio>
                    <NuxtLink :to="localePath({ name: 'portfolio-slug' })">{{ t('project_preview.portfolio') }}</NuxtLink>
                </template>
            </i18n-t>
            <i18n-t
                v-else-if="isDoen"
                keypath="project_preview.more_partners"
            >
                <template #partners>
                    <NuxtLink :to="localePath({ name: 'projecten-slug' })">{{ t('project_preview.partners') }}</NuxtLink>
                </template>
            </i18n-t>
            <i18n-t
                v-else
                keypath="project_preview.more_partners"
            >
                <template #partners>
                    <NuxtLink :to="localePath({ name: 'projecten-slug' })">{{ t('project_preview.partners') }}</NuxtLink>
                </template>
            </i18n-t>
        </h3>
        <div
            v-if="isDoen"
            class="more-projects__icons"
        >
            <BoomerIcon class="more-projects__icons__green"><icon-theme-green /></BoomerIcon>
            <BoomerIcon class="more-projects__icons__social"><icon-theme-social /></BoomerIcon>
            <BoomerIcon class="more-projects__icons__creative"><icon-theme-creative /></BoomerIcon>
        </div>
    </div>
</template>

<script setup>
import IconThemeGreen from '~/components/_icons/theme-green.vue';
import IconThemeSocial from '~/components/_icons/theme-social.vue';
import IconThemeCreative from '~/components/_icons/theme-creative.vue';

const { isDoen, isParticipaties } = useMultisite();

const localePath = useLocalePath();
const { t } = useI18n();
</script>

<style src="./MoreProjects.less" lang="less" scoped />
