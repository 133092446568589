<template>
    <div>
        <HomeHero :cases-or-projects />
        <FlexibleSection
            v-if="entry"
            :sections="entry?.flexibleSection"
        />
    </div>
</template>

<script setup>
import EntryQuery from '~/graphql/queries/Entry.graphql';

const { variables } = useDefaultVariables();

const response = await useAsyncQuery({
    query: EntryQuery,
    variables
});

const { craftEntry: entry } = useCraftPageEther(response);

const casesOrProjects = computed(() => entry?.value?.featuredCaseOrProject ?? null);
</script>
